import React from 'react';
import {
  CalculatePriceCouponResponse,
  Plan,
  Price,
} from '@laminar-product/client-commons-core/core';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import PlanDetails from 'components/OrderSummary/PlanDetails';
import CouponDetails from 'components/OrderSummary/CouponDetails';
import PaymentSummary from 'components/OrderSummary/PaymentSummary';
import styles from './index.module.scss';
import OrderBillingInformation from './PaymentSummary/OrderBillingInformation';

interface OrderSummaryProps {
  plan: Plan;
  price: Price;
  promoCode: string;
  setPromoCode: (code: string) => void;
  onPromoCodeApply: (code: string) => void;
  showTitle?: boolean;
  calculatedPriceCoupon?: CalculatePriceCouponResponse;
  resetCoupon: () => void;
  couponError?: string;
  taxAmount?: number;
}
const OrderSummary = ({
  plan,
  price,
  promoCode,
  setPromoCode,
  onPromoCodeApply,
  showTitle = true,
  calculatedPriceCoupon,
  resetCoupon,
  couponError,
  taxAmount,
}: OrderSummaryProps) => {
  const { t } = useTranslation();
  const shouldEnableCouponEntry = price?.gateway;

  return (
    <div>
      {showTitle && (
        <div className={styles.orderTitle}>
          <span>{t('orderSummary.heading')}</span>
        </div>
      )}
      <div className={styles.root}>
        <div
          className={cn(styles.details, {
            [styles.noPaddingTop]: !showTitle,
            [styles.detailsWithDiscount]: !!price.promotion,
          })}
        >
          <PlanDetails plan={plan} price={price} />
          {shouldEnableCouponEntry && (
            <CouponDetails
              promoCode={promoCode}
              setPromoCode={setPromoCode}
              onPromoCodeApply={onPromoCodeApply}
              calculatedPriceCoupon={calculatedPriceCoupon}
              resetCoupon={resetCoupon}
              couponError={couponError}
            />
          )}
        </div>
        <PaymentSummary
          price={price}
          calculatedPriceCoupon={calculatedPriceCoupon}
          taxAmount={taxAmount}
        />
      </div>
      <OrderBillingInformation
        price={price}
        calculatedPriceCoupon={calculatedPriceCoupon}
      />
    </div>
  );
};

export default OrderSummary;
