import {
  BillingAddress,
  BillingName,
  CalculatePriceCouponResponse,
  PaymentGateway,
  PayuPaymentResponse,
  Plan,
} from '@laminar-product/client-commons-core/core';
import api from 'api';
import { AddCardResponse } from 'types/card';
import {
  HandlePaymentParams,
  PaymentStatusData,
  StripePaymentResponse,
} from 'types/payment';
import {
  ChangeSubscriptionPaymentMethodResponse,
  CreateSubscriptionRazorpayData,
  CreateSubscriptionResponse,
  Subscription,
} from 'types/subscription';

export const getPlans = async () => {
  const { data } = await api().get<Plan[]>('/payments/plan/v2');
  return data;
};

export const cancelSubscription = () => api().delete('/payments/subscription');

export const getCurrentSubscription = () =>
  api()
    .get<Subscription>('/payments/subscription')
    .then((res) => res.data);

/**
 * Sets current user card/payment method.
 * https://stripe.com/docs/payments/payment-methods
 * This can be obtained using Stripe.js Elements - checkout CardForm component.
 *
 * @param {paymentMethodId} id of the PaymentMethod
 */
export const addCard = async (email: string) =>
  (await api().post<AddCardResponse>('/payments/card', { email })).data;

export const removeCard = () => api().delete('/payments/card');

export const getCard = async () => {
  const { data } = await api().get<string>('/payments/card');

  return data;
};

export const createPayuPayment = async (
  priceId: string,
  coupon?: string,
  paymentEmail?: string,
  offerId?: string
) => {
  const { data } = await api().post<PayuPaymentResponse>(
    '/payments/payment/create',
    {
      priceId,
      coupon,
      email: paymentEmail,
      offerId,
    }
  );

  return data;
};

interface StripePaymentParams {
  priceId: string;
  coupon?: string;
  paymentEmail?: string;
  offerId?: string;
  billingName?: BillingName;
  billingAddress?: BillingAddress;
}

export const createStripePayment = async ({
  priceId,
  coupon = '',
  paymentEmail,
  offerId,
  billingName,
  billingAddress,
}: StripePaymentParams) => {
  const { data } = await api().post<{ data: { id: string }; gateway?: string }>(
    '/payments/payment/create',
    {
      priceId,
      coupon,
      email: paymentEmail,
      offerId,
      ...(billingName && { billingName }),
      ...(billingAddress && { billingAddress }),
    }
  );

  return data;
};

interface CCBILLCreatePaymentResponse {
  gateway: PaymentGateway;
  data: {
    id: string;
    externalId: string;
    checkoutUrl: string;
  };
}

export const createCCBILLPayment = async ({
  priceId,
  couponId,
  email,
  offerId,
}: HandlePaymentParams) => {
  const { data } = await api().post<CCBILLCreatePaymentResponse>(
    '/payments/payment/create',
    {
      priceId,
      coupon: couponId,
      email,
      offerId,
    }
  );

  return data;
};

export const createRazorpayPayment = async (
  priceId?: string,
  offerId?: string,
  couponCode?: string
) => {
  const { data } = await api().post<CreateSubscriptionResponse>(
    '/payments/subscription/create',
    {
      priceId,
      offerId,
      couponCode,
    }
  );

  return data;
};

export const createSubscription = async (priceId: string) => {
  const { data } = await api().post<CreateSubscriptionResponse>(
    '/payments/subscription/create',
    {
      priceId,
    }
  );

  return data;
};

export const changeSubscriptionPaymentMethod = async () => {
  const { data } = await api().post<ChangeSubscriptionPaymentMethodResponse>(
    '/payments/subscription/payment-method'
  );

  return data;
};

export const applyPromoCode = async (priceId: string, coupon: string) =>
  api()
    .post<CalculatePriceCouponResponse>(
      `/payments/price/${priceId}/calculation`,
      {
        coupon,
      }
    )
    .then((res) => res.data);

export const getPaymentStatus = async (paymentId: string) => {
  const { data } = await api().get<PaymentStatusData>(
    `/payments/payment/status/${paymentId}`
  );
  return data;
};

export const redeemPrepaidPin = async (pin: string) =>
  api().post<{ success: boolean }>(`/prepaid/pin`, {
    pin,
  });

export const getAttributedCheckout = (contextId: string, email: string) =>
  api()
    .get<{
      gateway: PaymentGateway;
      data:
        | PayuPaymentResponse['data']
        | StripePaymentResponse
        | CreateSubscriptionRazorpayData;
    }>(
      `/payments/attributed-checkout-init?contextId=${contextId}&email=${email}`
    )
    .then((res) => res.data);

export const getOfferId = async () => {
  const { data } = await api().get<{ offerId: string }>('/payments/offer');

  return data.offerId;
};
