import {
  BillingAddress,
  BillingName,
  PaymentGateway,
  Price,
} from '@laminar-product/client-commons-core/core';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setPaymentEmail } from 'store/registration/actions';
import {
  upgradePlanParamId,
  upgradePlanParamName,
  upgradePlanParamType,
} from 'utils/constants';
import { Severity } from 'types/errors';
import useHandleStripePayment from './useHandleStripePayment';
import useHandlePayuPayment from './useHandlePayuPayment';
import useHandleRazorpayPayment from './useHandleRazorpayPayment';
import useHandleCCBILLPayment from './useHandleCCBILLPayment';
import { captureError } from './captureError';

const useHandlePayment = ({
  email = '',
  price,
  valid,
  offerId,
  promoCode = '',
  billingInfo,
}: {
  email?: string;
  price?: Price;
  valid: boolean;
  offerId?: string;
  promoCode?: string;
  billingInfo?: {
    name: BillingName;
    address: BillingAddress;
  };
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { handlePayuPayment } = useHandlePayuPayment();
  const { handleStripePayment } = useHandleStripePayment();
  const { handleRazorpayPayment, razorpayPaymentErrorCode } =
    useHandleRazorpayPayment();
  const { handleCCBILLPayment } = useHandleCCBILLPayment();
  const { gateway: pricePaymentGateway, uuid } = price || {};

  const sendPayment = useCallback(async () => {
    dispatch(setPaymentEmail(email));

    if (!valid) return;

    if (location.search.length) {
      const queryParams = new URLSearchParams(location.search);
      window.sessionStorage.setItem(
        'upgradePlanData',
        JSON.stringify({
          id: queryParams.get(upgradePlanParamId),
          type: queryParams.get(upgradePlanParamType),
          name: queryParams.get(upgradePlanParamName),
        })
      );
    }

    setLoading(true);

    try {
      if (pricePaymentGateway === PaymentGateway.Stripe)
        await handleStripePayment({
          priceId: uuid ?? '',
          email: email || '',
          couponId: promoCode,
          offerId,
          billingName: billingInfo?.name,
          billingAddress: billingInfo?.address,
        });

      if (pricePaymentGateway === PaymentGateway.Payu)
        await handlePayuPayment({
          priceId: uuid ?? '',
          email,
          couponId: promoCode,
          offerId,
        });

      if (pricePaymentGateway === PaymentGateway.RAZORPAY && uuid) {
        await handleRazorpayPayment({
          priceId: uuid ?? '',
          email: email || '',
          couponId: promoCode,
          offerId,
        });
      }

      if (pricePaymentGateway === PaymentGateway.CCBILL && uuid) {
        await handleCCBILLPayment({
          priceId: uuid ?? '',
          email,
          couponId: promoCode,
          offerId,
        });
      }
    } catch (error: any) {
      captureError(
        error,
        `set payment: ${pricePaymentGateway}`,
        Severity.Error
      );
    } finally {
      setLoading(false);
    }
  }, [
    dispatch,
    email,
    billingInfo,
    handleCCBILLPayment,
    handlePayuPayment,
    handleRazorpayPayment,
    handleStripePayment,
    location.search,
    offerId,
    pricePaymentGateway,
    promoCode,
    uuid,
    valid,
  ]);

  return {
    sendPayment,
    isLoading,
    paymentErrorCode: razorpayPaymentErrorCode,
  };
};

export default useHandlePayment;
