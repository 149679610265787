import { useEffect, useState } from 'react';
import { ReactComponent as PaymentSuccessIcon } from 'assets/images/payment-completed.svg';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button } from '@laminar-product/client-commons-core/web';
import {
  AssetType,
  getAssetDetailsPath,
} from '@laminar-product/client-commons-core/core';
import styles from './index.module.scss';

interface PaymentCompletedProps {
  refreshUser: () => void;
}

interface UpgradeRedirectData {
  type: AssetType;
  name: string;
  id: string;
}

interface PlanInfo {
  administrativeName: string;
  amount: number;
  currency: string;
  intervalMultiplier: number;
  interval: string;
}

const getRedirectUrl = (upgradeRedirectData?: UpgradeRedirectData): string => {
  if (!upgradeRedirectData) return '/';
  const { type, id, name } = upgradeRedirectData;

  return !type
    ? `/${name}/${id}`
    : getAssetDetailsPath({ id, type, administrativeName: name });
};

const PaymentCompleted = ({ refreshUser }: PaymentCompletedProps) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const [planInfo, setPlanInfo] = useState<PlanInfo | null>(null);
  const [upgradeRedirectData, setUpgradeRedirectData] =
    useState<UpgradeRedirectData>();

  useEffect(() => {
    const data = window.sessionStorage.getItem('upgradePlanData');
    const upgradePlanData = data ? JSON.parse(data) : null;
    if (upgradePlanData) {
      setUpgradeRedirectData(upgradePlanData);
    }

    return () => window.sessionStorage.removeItem('upgradePlanData');
  }, []);

  useEffect(() => {
    const data = window.sessionStorage.getItem('planInfo');
    const planInfoData = data ? JSON.parse(data) : null;
    if (planInfoData) {
      setPlanInfo(planInfoData);
    }

    return () => window.sessionStorage.removeItem('planInfo');
  }, []);

  const onClick = () => {
    refreshUser();
    const redirectUrl = getRedirectUrl(upgradeRedirectData);
    push(redirectUrl);
  };

  return (
    <div className={styles.root}>
      <div className={styles.imgWrapper}>
        <PaymentSuccessIcon className={styles.img} />
      </div>
      <h1 className={styles.heading}>{t('payment.success.heading')}</h1>
      <p className={styles.description}>
        <Trans
          i18nKey="payment.success.description"
          values={{
            planName: planInfo?.administrativeName,
            amount: (planInfo?.amount! * 1) / 100,
            currency: planInfo?.currency,
            intervalMultiplier: planInfo?.intervalMultiplier,
            interval: planInfo?.interval,
          }}
        />
      </p>
      <Button variant="cta" onClick={onClick}>
        {t('payment.success.cta')}
      </Button>
    </div>
  );
};

export default PaymentCompleted;
