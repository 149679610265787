import React, { useCallback, useMemo, useState } from 'react';
import {
  BillingAddress,
  BillingName,
  CalculatePriceCouponResponse,
} from '@laminar-product/client-commons-core/core';
import { Button, Modal } from '@laminar-product/client-commons-core/web';
import { useFetchAction } from '@laminar-product/client-commons-core/hooks';
import OrderSummary from 'components/OrderSummary';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Input from 'components/Input';
import cn from 'classnames';
import { Redirect } from 'react-router-dom';
import { applyPromoCode, getOfferId } from 'actions/payments';
import { isEmail } from 'utils/validators';
import { checkForCouponApplied } from 'utils/price';
import useTaxBilling from 'utils/useTaxBilling';
import BillingFormContainer from 'components/BillingFormContainer';
import AppLoadingIndicator from 'components/AppLoadingIndicator';
import BillingForm from 'components/BillingForm';
import {
  selectRegistrationSelectedPlan,
  selectRegistrationSelectedPrice,
  selectRegistrationUserPaymentEmail,
} from 'store/registration/selectors';
import { selectUserEmail } from 'store/user/selectors';
import useHandlePayment from 'utils/useHandlePayment';
import { mapPaymentErrorToMessage } from 'utils/payments';
import i18n from 'i18n';
import styles from './index.module.scss';

interface SetPaymentProps {
  isUpgradingPlan: boolean;
}

const SetPayment = ({ isUpgradingPlan }: SetPaymentProps) => {
  const { t } = useTranslation();
  const [emailError, setEmailError] = useState<string>();
  const [email, setEmail] = useState('');
  const [couponError, setCouponError] = useState<string>();
  const [promoCode, setPromoCode] = useState('');
  const [billingInfo, setBillingInfo] = useState<{
    name: BillingName;
    address: BillingAddress;
  }>();
  const [calculatedPriceCoupon, setCalculatedPriceCoupon] =
    useState<CalculatePriceCouponResponse>();
  const [isBillingAddressModalOpen, setIsBillingAddressModalOpen] =
    useState(false);

  const paymentEmail = useSelector(selectRegistrationUserPaymentEmail);
  const userEmail = useSelector(selectUserEmail);
  const selectedPlan = useSelector(selectRegistrationSelectedPlan);
  const selectedPrice = useSelector(selectRegistrationSelectedPrice);
  const { uuid, captureBillingAddress, captureBillingName } =
    selectedPrice || {};

  const missingPaymentEmail = !email && !userEmail && !paymentEmail;

  const [offerId, isLoadingOfferId] = useFetchAction<string>(getOfferId);
  const closeBillingAddressModal = useCallback(
    () => setIsBillingAddressModalOpen(false),
    []
  );
  const [couponMetadataContainerModalOpen, setCouponMetadataModalOpen] =
    useState(false);
  const [couponMetaData, setCouponMetaData] =
    useState<CalculatePriceCouponResponse>();
  const {
    billingAddress,
    taxValidation,
    isBillingAddressValid,
    taxEstimation,
    isTaxLoading,
    saveBillingAddress,
    isSavingBillingAddress,
    saveBillingAddressError,
  } = useTaxBilling(closeBillingAddressModal, offerId);

  const isValidPayment = useMemo((): boolean => {
    if (missingPaymentEmail) {
      setEmailError(t('common.validations.required'));
      return false;
    }

    if (paymentEmail && !isEmail(paymentEmail)) {
      setEmailError(t('common.validations.wrongEmail'));
      return false;
    }

    return true;
  }, [missingPaymentEmail, paymentEmail, t]);

  const {
    sendPayment,
    isLoading: paymentLoading,
    paymentErrorCode,
  } = useHandlePayment({
    email: email || userEmail || paymentEmail,
    price: selectedPrice,
    valid: isValidPayment,
    offerId,
    promoCode,
    billingInfo,
  });

  const onPromoCodeApply = useCallback(async () => {
    if (!uuid) return;
    try {
      const priceAfterCoupon = await applyPromoCode(uuid, promoCode);

      if (!priceAfterCoupon) return;

      setCalculatedPriceCoupon(priceAfterCoupon);

      if (!checkForCouponApplied(priceAfterCoupon)) {
        setCouponError(t('orderSummary.couponNotApplied'));
      }
      if (priceAfterCoupon?.netPrice) {
        setCouponMetaData(priceAfterCoupon!);
        setCouponMetadataModalOpen(true);
      }
    } catch (error) {
      setCouponError(t('orderSummary.couponError'));
      setPromoCode('');
    }
  }, [promoCode, t, uuid]);

  const resetCoupon = useCallback(() => {
    setPromoCode('');
    setCalculatedPriceCoupon(undefined);
    setCouponError('');
  }, []);

  const billingInfoRequired = useMemo(
    () =>
      taxValidation?.billingAddressRequired ||
      captureBillingAddress ||
      captureBillingName,
    [captureBillingAddress, captureBillingName, taxValidation]
  );

  if (!selectedPlan || !selectedPrice) {
    if (isUpgradingPlan)
      return <Redirect to={{ pathname: '/settings/change-plan' }} />;

    return <Redirect to={{ pathname: '/register/plan' }} />;
  }

  if (isLoadingOfferId || isTaxLoading) {
    return (
      <div className={styles.root}>
        <AppLoadingIndicator />
      </div>
    );
  }

  const showMetaDataAlert = (data: CalculatePriceCouponResponse) => {
    const currentLanguage = i18n.language || 'en';
    const discountWithMetadata = data?.discounts?.find(
      (val) => val?.metadata[currentLanguage] || val?.metadata?.en
    );

    if (discountWithMetadata) {
      const { name, description } =
        discountWithMetadata.metadata[currentLanguage] ||
        discountWithMetadata.metadata.en;
      return { name, description };
    }
  };
  const couponMetaDataContainer = () => {
    const data = showMetaDataAlert(couponMetaData!);

    return (
      <div className={styles.modalContent}>
        <h1>{data?.name}</h1>
        <p>{data?.description}</p>
        <Button onClick={() => setCouponMetadataModalOpen(false)}>
          {t('common.close')}
        </Button>
      </div>
    );
  };

  const onClickPay = () => {
    if (isValidPayment) {
      sendPayment();
      window.sessionStorage.setItem(
        'planInfo',
        JSON.stringify({
          administrativeName: selectedPlan?.administrativeName,
          amount: selectedPrice?.amount,
          currency: selectedPrice?.currency,
          intervalMultiplier: selectedPrice?.intervalMultiplier,
          interval: selectedPrice?.interval,
        })
      );
    }
  };

  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>Payment</h1>
      <div className={styles.columns}>
        {billingInfoRequired && (
          <div className={styles.billingAddress}>
            <BillingFormContainer
              openModal={() => setIsBillingAddressModalOpen(true)}
              billingAddress={billingAddress}
              isValid={isBillingAddressValid}
            />
            <Modal
              className={styles.modal}
              wrapperId="billingAddressFormModal"
              isOpen={isBillingAddressModalOpen}
            >
              <BillingForm
                billingAddress={billingAddress}
                onCancel={closeBillingAddressModal}
                onSave={saveBillingAddress}
                setBillingInfo={setBillingInfo}
                isLoading={isSavingBillingAddress}
                error={saveBillingAddressError}
                fullNameRequired={captureBillingName}
                regionRequired={taxValidation?.regionRequired}
                availableRegions={taxValidation?.availableRegions}
              />
            </Modal>
          </div>
        )}
        <div className={styles.orderSummary}>
          <OrderSummary
            plan={selectedPlan}
            price={selectedPrice}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            onPromoCodeApply={onPromoCodeApply}
            calculatedPriceCoupon={calculatedPriceCoupon}
            resetCoupon={resetCoupon}
            couponError={couponError}
            taxAmount={taxEstimation?.total?.taxAmount}
          />
          <Modal
            className={styles.metadataModal}
            wrapperId="metadataModal"
            isOpen={couponMetadataContainerModalOpen}
          >
            {couponMetaDataContainer()}
          </Modal>
        </div>
        {!userEmail && (
          <div className={styles.row}>
            <Input
              light
              id="email"
              placeholder="Enter your email here"
              onChange={(e) => setEmail(e.target.value)}
              required={!!emailError}
            />

            {emailError && (
              <span className={styles.emailErrorMessage}>{emailError}</span>
            )}
          </div>
        )}

        <div className={cn(styles.row, styles.payment)}>
          {paymentErrorCode && (
            <div className={styles.paymentErrorMessage}>
              {mapPaymentErrorToMessage(paymentErrorCode)}
            </div>
          )}

          <Button
            loading={paymentLoading}
            className={styles.payButton}
            variant="cta"
            onClick={onClickPay}
            disabled={billingInfoRequired && !isBillingAddressValid}
            data-testid="Payment_PayButton"
          >
            <Trans i18nKey="settings.pay">Pay</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SetPayment;
